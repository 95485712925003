import { Loading } from '@nextui-org/react';
import classNames from 'classnames';
import React, { useRef, useEffect } from 'react';

// divider y 취사선택
export const InfiniteScroll: React.FC<{
  onLoadMore?: () => void;
  onLoadButton?: React.ReactNode;
  children: React.ReactNode;
  isLoading: boolean;
  className?: string;
}> = ({ onLoadMore, onLoadButton, isLoading, children, className }) => {
  const observerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const handleIntersection = (entries: any) => {
      const target = entries[0];
      if (target.isIntersecting && !isLoading) {
        onLoadMore?.();
      }
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [isLoading, onLoadMore]);

  return (
    <ul
      role="list"
      className={classNames('flex flex-col w-full px-4', className)}
    >
      {children}
      {isLoading && (
        <div className="w-full py-2 text-center">
          <Loading></Loading>
        </div>
      )}
      {onLoadButton ? (
        <div className="w-full mb-2 mx-auto">{onLoadButton}</div>
      ) : (
        <div ref={observerRef} />
      )}
    </ul>
  );
};
