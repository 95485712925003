import { useContext } from 'react';

import { SurveyContext } from '../contexts/SurveyContext';

export const useSurvey = () => {
  const {
    loading,
    step,
    stepProps,
    steps,
    next,
    prev,
    go,
    onDone,
    onItemSelect,
    onPrevStep,
    onNextStep,
    onClose,
  } = useContext(SurveyContext);

  return {
    loading,
    step,
    stepProps,
    steps,
    next,
    prev,
    go,
    onDone,
    onItemSelect,
    onPrevStep,
    onNextStep,
    onClose,
  };
};
