import React from 'react';
import classNames from 'classnames';

export interface BannerProps {
  className?: string;
  variant?: 'info' | 'warn' | 'error' | 'dark';
  children: React.ReactNode;
}

const VariantMap = {
  info: 'bg-info-background',
  warn: 'bg-warn-background',
  error: 'bg-error-background',
  dark: 'bg-gray-90',
};

export const Banner: React.FC<BannerProps> = ({
  className,
  children,
  variant = 'info',
}) => {
  return (
    <p
      className={classNames(
        'text-caption py-3 px-5',
        VariantMap[variant],
        className
      )}
    >
      {children}
    </p>
  );
};
