import classNames from 'classnames';
import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  InputHTMLAttributes,
  MouseEventHandler,
} from 'react';
import { MdCheckCircle } from 'react-icons/md';

interface InputProps {
  htmlProps?: Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    keyof InputProps
  >;
  inputProps?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  className?: string;
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  inputOff?: boolean;
}

export const Checkbox: React.FC<InputProps> = ({
  htmlProps,
  inputProps,
  className,
  children,
  onClick,
  inputOff,
}) => {
  return (
    <div
      {...htmlProps}
      className={classNames(
        'relative flex flex-row justify-between p-5 bg-background-light rounded-2xl text-body-md cursor-pointer hover:opacity-70',
        className
      )}
      onClick={onClick}
    >
      {!inputOff && (
        <input
          className="rounded-2xl absolute top-0 left-0 w-full h-full opacity-0 peer cursor-pointer"
          {...inputProps}
        />
      )}
      <div className="flex-1">{children}</div>
      <MdCheckCircle
        className={classNames('peer-checked:text-primary text-gray-60')}
        size={28}
      />
    </div>
  );
};
