import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { Button } from '../Button';

export interface AddressSearchBarProps {
  inputRef?: React.Ref<HTMLInputElement>;
  classes?: {
    container?: string;
    input?: string;
    button?: string;
  };
  onSearch?: (keyword: string) => void;
}

export const AddressSearchBar: React.FC<AddressSearchBarProps> = ({
  classes,
  onSearch,
}) => {
  const [keyword, setKeyword] = React.useState<string>('');
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <form
      className={classNames(
        'flex flex-row justify-between',
        'rounded-2xl border border-border-10 p-2',
        'text-gray-90',
        classes?.container
      )}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        ref.current?.blur();
        onSearch?.(keyword);
      }}
    >
      <input
        ref={ref}
        value={keyword}
        size={2}
        onChange={(e) => setKeyword(e.target.value)}
        className={classNames(
          ' px-2 flex-1 text-label-1 placeholder:text-gray-60 mr-2',
          'outline-none focus-visible:outline focus-visible:outline-primary focus-visible:outline-2 focus-visible:outline-offset-2 rounded-[10px]',
          classes?.input
        )}
        placeholder={'지번주소, 건물명, 도로명 검색'}
      />
      <Button
        className={classNames(classes?.button)}
        onClick={() => onSearch?.(keyword)}
      >
        검색
      </Button>
    </form>
  );
};
