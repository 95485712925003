import React from 'react';
import classNames from 'classnames';
import { MdCheckCircle } from 'react-icons/md';

import { AddressItemData } from './types';

export interface AddressSearchItemProps {
  className?: string;
  isSelected?: boolean;
  onClick?: (address: AddressItemData) => void;
  address: AddressItemData;
}

export const AddressSearchItem: React.FC<AddressSearchItemProps> = ({
  className,
  address,
  isSelected = false,
  onClick,
}) => {
  return (
    <li
      onClick={(e) => {
        e.preventDefault();
        onClick?.(address);
      }}
      className={classNames(
        className,
        'flex flex-row justify-between',
        'p-5 rounded-2xl',
        'cursor-pointer',
        isSelected ? 'bg-primary-background' : 'bg-background-light',
        'text-gray-70 text-body-md'
      )}
    >
      <section className="flex flex-row mr-3 flex-1">
        <table>
          <tbody>
            <tr>
              <td className="w-[90px]">우편번호</td>
              <td className="text-gray-90 font-bold">{address.zipCode}</td>
            </tr>
            <tr>
              <td>지번주소</td>
              <td>{address.jibunAddress}</td>
            </tr>
            <tr>
              <td>도로명</td>
              <td>{address.roadAddress}</td>
            </tr>
          </tbody>
        </table>
      </section>
      <MdCheckCircle
        size={28}
        className={classNames(isSelected ? 'text-primary' : 'text-gray-60')}
      />
    </li>
  );
};
