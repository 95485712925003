import { useBexUser } from '@packages/bex'
import { useCallback } from 'react'
import { Types } from '@amplitude/analytics-browser'

import {
  AmplitudeEventLogger,
  RevenueProps,
} from '../lib/analyze/AmplitudeEventLogger'

export const useEventLogging = () => {
  const eventLogger = AmplitudeEventLogger.getInstance()
  const { setBexUserId } = useBexUser()

  const getPlatform = () => {
    return window.flutter_inappwebview ? 'app' : 'web'
  }

  const setUserId = useCallback(
    ({ userId }: { userId: string | undefined }) => {
      if (userId) {
        setBexUserId({ userId })
      }
      eventLogger.setUserId(userId)
    },
    [eventLogger]
  )

  const getUserId = useCallback(() => {
    return eventLogger.getUserId()
  }, [eventLogger])

  const setUserProperties = useCallback(
    ({ properties }: { properties: object | null }) => {
      eventLogger.setUserProperties(properties)
    },
    [eventLogger]
  )

  const track = useCallback(
    ({
      name,
      properties,
      options,
    }: {
      name: string
      properties?: object
      options?: Types.EventOptions
    }) => {
      eventLogger.track(
        name,
        {
          platform: getPlatform(),
          ...properties,
        },
        options
      )
    },
    [eventLogger]
  )

  const trackButtonClick = useCallback(
    ({
      name,
      properties,
      pagePath,
      options,
    }: {
      name: string
      properties?: object
      pagePath?: string
      options?: Types.EventOptions
    }) => {
      eventLogger.trackButtonClick(
        name,
        { platform: getPlatform(), ...properties },
        pagePath,
        options
      )
    },
    [eventLogger]
  )

  const trackAction = useCallback(
    ({
      name,
      properties,
      pagePath,
      options,
    }: {
      name: string
      properties?: object
      pagePath?: string
      options?: Types.EventOptions
    }) => {
      eventLogger.trackAction(
        name,
        { platform: getPlatform(), ...properties },
        pagePath,
        options
      )
    },
    [eventLogger]
  )

  const trackPageView = useCallback(
    ({
      pagePath,
      pageName,
      properties,
      options,
    }: {
      pagePath?: string
      pageName: string
      properties?: object
      options?: Types.EventOptions
    }) => {
      eventLogger.trackPageView(
        pagePath,
        pageName,
        {
          platform: getPlatform(),
          ...properties,
        },
        options
      )
    },
    [eventLogger]
  )

  const trackShow = useCallback(
    ({
      name,
      pagePath,
      properties,
      options,
    }: {
      name: string
      pagePath?: string
      properties?: object
      options?: Types.EventOptions
    }) => {
      eventLogger.trackShow(
        name,
        pagePath,
        {
          platform: getPlatform(),
          ...properties,
        },
        options
      )
    },
    [eventLogger]
  )

  const trackRevenue = useCallback(
    (revenueProps: RevenueProps) => {
      eventLogger.trackRevenue({
        ...revenueProps,
        eventProperties: {
          ...revenueProps?.eventProperties,
          platform: getPlatform(),
        },
      })
    },
    [eventLogger]
  )

  return {
    getUserId,
    setUserId,
    setUserProperties,
    track,
    trackButtonClick,
    trackAction,
    trackPageView,
    trackShow,
    trackRevenue,
  }
}
