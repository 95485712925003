import React from 'react';
import classNames from 'classnames';

type BottomFixedLayoutProps = {
  children?: React.ReactNode;
  className?: string;
};

export const BottomFixedLayout: React.FC<BottomFixedLayoutProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        'flex w-full max-w-lg bg-opacity-80 fixed bottom-0 z-10 bg-white p-6',
        className || ''
      )}
    >
      {children}
    </div>
  );
};
