import React from 'react';
import { useFormContext } from 'react-hook-form';

import { SurveyItemProps } from './types';
import { Select } from '../Form/Select/Select';
import { MultiSelect } from '../Form/MultiSelect/MultiSelect';

export const SurveyItem: React.FC<SurveyItemProps> = ({
  className,
  type,
  placeholder,
  rows = 1,
  cols = 1,
  title,
  name,
  required,
  options,
}) => {
  const { setValue } = useFormContext();
  return (
    <div className={className}>
      {title}
      {type === 'select' ? (
        <Select cols={cols} name={name} required={required} options={options} />
      ) : type === 'multi-select' ? (
        <MultiSelect
          cols={cols}
          name={name}
          required={required}
          options={options}
        />
      ) : type === 'textarea' ? (
        <textarea
          name={name}
          className="p-4 w-full placeholder:text-gray-60 resize-none bg-background-light rounded-xl"
          placeholder={placeholder}
          rows={rows}
          onChange={(e) => {
            setValue(name, e.target.value);
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
