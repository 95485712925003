import React, { useMemo } from 'react';
import classNames from 'classnames';

import { AddressSearchItem } from './Item';
import { useAddressSearch } from './hooks';
import { Tip } from '../Tip';

export interface AddressSearchListProps {
  classes?: {
    container?: string;
    item?: string;
  };
  targetRef?: React.RefObject<HTMLElement>;
}

export const AddressSearchList: React.FC<AddressSearchListProps> = ({
  classes,
  targetRef,
}) => {
  const { addresses, isFetched, isFetching, selectedAddress, onSelected } =
    useAddressSearch();

  const tip = useMemo(
    () => (
      <Tip
        variant="dark"
        withIcon={false}
        title={
          <>
            <span className="mr-2 bg-gray-90 text-white rounded-full align-middle px-1 py-0.5 text-label-3-b">
              도움말
            </span>
            <span className="align-middle">아래 예시와 같이 입력해 보세요</span>
          </>
        }
        description={
          <>
            {'예) 연희동 42-18'}
            <br />
            {'예) 무학로 33 도산대로 8길 23'}
            <br />
            {'예) 역삼동 푸르지오'}
          </>
        }
      />
    ),
    []
  );

  return (
    <ul className={classNames('flex flex-col gap-y-2', classes?.container)}>
      {!isFetched && !isFetching ? (
        <section>
          <p className="px-6 pb-4 text-center text-caption-b text-gray-90">
            주소 입력 후 검색 버튼을 눌러보세요
          </p>
          {tip}
        </section>
      ) : isFetched && !addresses?.length ? (
        <section className="">
          <section className="p-6">
            <p className="mb-0.5 text-center text-caption-b text-gray-90">
              찾는 주소가 없어요
            </p>
            <p className=" text-center text-caption text-gray-70">
              검색어에 잘못된 철자가 없는지 다시 한번 확인해주세요
            </p>
          </section>

          {tip}
        </section>
      ) : (
        <>
          {addresses?.map((address) => (
            <AddressSearchItem
              key={`${address.jibunAddress}_${address.roadAddress}`}
              className={classes?.item}
              address={address}
              onClick={onSelected}
              isSelected={
                selectedAddress?.jibunAddress === address.jibunAddress &&
                selectedAddress.roadAddress === address.roadAddress
              }
            />
          ))}
          {!isFetched ? (
            <section className="h-10 flex flex-row justify-center items-center">
              <p className="text-center text-caption">
                주소를 가져오는 중입니다.
              </p>
            </section>
          ) : (
            <section className="h-10" ref={targetRef}>
              &nbsp;
            </section>
          )}
        </>
      )}
    </ul>
  );
};
