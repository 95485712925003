import { createContext } from 'react';

import { SurveyProps, SurveyStepProps } from '../types';

export interface SurveyContextData extends SurveyProps {
  step: number;
  stepProps: SurveyStepProps;
  next: () => void;
  prev: () => void;
  go: (step: number) => void;
}

export const SurveyContext = createContext<SurveyContextData>({
  name: '',
  step: 1,
  stepProps: {
    name: '',
    items: [],
  },
  steps: [],
  next: () => null,
  prev: () => null,
  go: () => null,
});
