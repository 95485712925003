import classNames from 'classnames';
import React from 'react';
import { IconType } from 'react-icons/lib';
import {
  MdDoneAll,
  MdErrorOutline,
  MdInfoOutline,
  MdOutlineWarning,
} from 'react-icons/md';

export interface TipWrapperProps {
  icon?: IconType;
  withIcon?: boolean;
  withText?: boolean;
  iconTextLabel?: string;
  className?: string;
  variant?: 'error' | 'info' | 'warn' | 'primary' | 'dark';
  title: React.ReactNode;
  description?: string | React.ReactNode;
  action?: React.ReactNode;
}

const VariantIcon = {
  primary: MdDoneAll,
  info: MdInfoOutline,
  warn: MdOutlineWarning,
  error: MdErrorOutline,
  dark: MdDoneAll,
};

export const Tip: React.FC<TipWrapperProps> = ({
  icon,
  withIcon = true,
  withText = false,
  className,
  variant = 'info',
  iconTextLabel,
  title,
  description,
  action,
}) => {
  return (
    <div
      className={classNames(
        `p-4 text-caption rounded-xl flex flex-row gap-x-3`,
        {
          'bg-primary-background text-primary-text': variant === 'primary',
          'bg-info-background text-info': variant === 'info',
          'bg-warn-background text-warn': variant === 'warn',
          'bg-error-background text-error': variant === 'error',
          'bg-background-light text-gray-90': variant === 'dark',
        },
        className
      )}
    >
      {withIcon && (
        <div>
          {React.createElement(icon ?? VariantIcon[variant], { size: 24 })}
        </div>
      )}

      <div className="flex flex-col w-full">
        <h4 className={`text-caption-b`}>
          {withText && (
            <span
              className={classNames(
                'mr-2 text-label-3-b py-0.5 px-1.5 text-white rounded-full',
                {
                  'bg-info': variant === 'info',
                  'bg-error': variant === 'error',
                  'bg-gray-90': variant === 'dark',
                  'bg-warn': variant === 'warn',
                  'bg-primary': variant === 'primary',
                }
              )}
            >
              {iconTextLabel || '도움말'}
            </span>
          )}
          {title}
        </h4>
        {description &&
          (typeof description === 'string' ? (
            <p className="text-label-3 text-gray-70 mt-2">{description}</p>
          ) : (
            description
          ))}
        {action && <div className="mt-3">{action}</div>}
      </div>
    </div>
  );
};

export * from './V2';
