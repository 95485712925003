import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { IconType } from 'react-icons/lib';

export interface IconButtonProps {
  className?: string;
  icon: IconType;
  size?: 'small' | 'medium' | 'large';
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const SizeMap = {
  small: 'p-3',
  medium: 'p-4',
  large: 'p-6',
};

const IconSizeMap = {
  small: 20,
  medium: 24,
  large: 28,
};

export const IconButton: React.FC<IconButtonProps> = ({
  className,
  onClick,
  size = 'medium',
  icon: Icon,
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        SizeMap[size],
        'w-fit h-fit cursor-pointer',
        'disabled:text-gray-70 rounded-full focus:bg-background-light hover:bg-background-light active:bg-background-dark',
        className
      )}
    >
      <Icon size={IconSizeMap[size]} />
    </button>
  );
};
