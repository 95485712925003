import React, { Dispatch, SetStateAction } from 'react';

export interface FixedProps {
  open: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  position: 'top' | 'bottom';
  children: React.ReactNode;
  onClose?: (result?: any) => void;
}

export const Fixed: React.FC<FixedProps> = ({ open, children, position }) => {
  let pos = 'bottom-0';

  if (position === 'top') {
    pos = 'top-0';
  }

  const rootStyle = `fixed ${pos} left-0 w-full z-50`;

  if (!open) {
    return null;
  }

  return (
    <div className={rootStyle}>
      <div>
        <div>{children}</div>
      </div>
    </div>
  );
};
