import React from 'react';

export interface AspectRatioProps {
  height: number;
  width: number;
  children: React.ReactNode;
}

const BaseAspectRatio: React.FC<AspectRatioProps> = ({
  height,
  width,
  children,
}) => {
  return (
    <div
      style={{
        width: '100%',
        paddingTop: `${(height / width) * 100}%`,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const AspectRatio = React.memo(BaseAspectRatio);
