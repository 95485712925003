import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';

export interface TextButtonProps {
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  full?: boolean;
  underline?: boolean;
  children?: React.ReactNode;
}
export const TextButton: React.FC<TextButtonProps> = ({
  type = 'button',
  className,
  onClick,
  full,
  underline,
  children,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames(
        'text-gray-70 text-body-md h-10 hover:opacity-80 active:opacity-60 px-1 rounded-lg',
        full ? 'w-full' : null,
        underline ? 'underline' : null,
        className
      )}
    >
      {children}
    </button>
  );
};
