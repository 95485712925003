import classNames from 'classnames';
import React from 'react';

export interface ChipProps {
  className?: string;
  variant?:
    | 'info'
    | 'warn'
    | 'error'
    | 'dark'
    | 'gray'
    | 'error-background'
    | 'primary-low';
  size?: 'small' | 'medium' | 'large';
  children?: React.ReactNode;
  block?: boolean;
}

const VariantMap = {
  info: 'text-white bg-info',
  warn: 'text-white bg-warn',
  error: 'text-white bg-error',
  dark: 'text-white bg-gray-90',
  gray: 'text-black bg-border-10',
  'primary-low': 'text-primary-text bg-primary-background',
  'error-background': 'text-error bg-error-background',
};

const SizeMap = {
  small: 'px-1.5 py-0.5 text-label-3-b',
  medium: 'px-2 py-1 text-label-2-b',
  large: 'px-4 py-2 text-label-1-b',
};

export const Chip: React.FC<ChipProps> = ({
  className,
  variant = 'info',
  size = 'small',
  children,
  block = false,
}) => {
  return (
    <span
      className={classNames(
        'rounded-full',
        SizeMap[size],
        VariantMap[variant],
        {
          block: block,
        },
        className
      )}
    >
      {children}
    </span>
  );
};
