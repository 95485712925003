import classNames from 'classnames';

export const Terms = ({
  title,
  terms,
  className,
}: {
  title: string;
  terms: string[];
  className?: { layout?: string; title?: string; ul?: string; li?: string };
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col flex-1 justify-stretch bg-background-light p-8',
        className?.layout || ''
      )}
    >
      <h3
        className={classNames(
          'text-gray-90 text-caption-b',
          className?.title || ''
        )}
      >
        {title}
      </h3>
      <ul
        className={classNames(
          'text-gray-70 text-caption space-y-[2px] list-none pl-5 mt-4',
          className?.ul || ''
        )}
      >
        {terms.map((term, ind) => {
          return (
            <li
              key={ind}
              className={classNames('list-disc', className?.li || '')}
            >
              {term}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
