import classNames from 'classnames';
import React from 'react';

interface DividerProps {
  className: string;
}

const Divider: React.FC<DividerProps> = ({ className }) => {
  return <div className={classNames(className, 'bg-appback')}></div>;
};

export default Divider;
