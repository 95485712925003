import classNames from 'classnames';
import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

export interface DropDownProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string;
  options?: { label: React.ReactNode; value: any }[];
  className?: string;
  placeholder?: string;
}

export const DropDown: React.FC<DropDownProps> = ({
  name,
  options = [],
  className,
  placeholder,
}) => {
  const { register } = useFormContext();
  return (
    <select
      id={name}
      className={classNames(
        "w-full text-body-md bg-background-light border-0 !p-5 !rounded-2xl appearance-none bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImtleWJvYXJkX2Fycm93X2Rvd24iPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNNy40MSA4Ljc5NDkyTDEyIDEzLjM3NDlMMTYuNTkgOC43OTQ5MkwxOCAxMC4yMDQ5TDEyIDE2LjIwNDlMNiAxMC4yMDQ5TDcuNDEgOC43OTQ5MloiIGZpbGw9IiMyQjI4MjYiLz4KPC9nPgo8L3N2Zz4K');] bg-[14px] bg-no-repeat bg-[right_1rem_center]",
        className
      )}
      {...register(name)}
    >
      {placeholder && (
        <option value={''} disabled selected>
          {placeholder}
        </option>
      )}
      {options &&
        options.map((option) => (
          <option
            key={`${name}-${option.value}`}
            // value가 빈 string이 들어가지 않으면, label이 value로 들어가기에 조건식 추가
            // value가 false인 경우도 있기에, undefined인 경우만을 특정지음
            value={option.value === undefined ? '' : option.value}
          >
            {option.label}
          </option>
        ))}
    </select>
  );
};
