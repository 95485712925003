import classNames from 'classnames';
import React, { useRef } from 'react';

interface FadeInSectionProps {
  height?: string;
  children: React.ReactNode;
  fadeIn?: boolean;
  fadeHeight?: string;
  transform?: boolean;
}

const FadeInSection: React.FC<FadeInSectionProps> = ({
  children,
  height = '100%',
  fadeIn = true,
  fadeHeight = '100px',
  transform = true,
}) => {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) setVisible(entry.isIntersecting);
      });
    });
    observer.observe(domRef.current as unknown as Element);
    return () => observer.disconnect();
  }, []);

  return (
    <div
      style={{
        transition: `visibility 1200ms ease-out${
          transform ? ', transform 1200ms ease-out' : ''
        }${fadeIn ? ', opacity 1200ms ease-out' : ''}`,
        transform: `translateY(${fadeHeight})`,
      }}
      className={classNames(
        'opacity-0 transition-opacity ease-in',
        'duration-700 invisible',
        'will-change-[opacity,visibility,transform]',
        { '!opacity-100 !translate-y-0 !visible': isVisible }
      )}
      ref={domRef}
    >
      {isVisible ? children : <div style={{ height }}></div>}
    </div>
  );
};

export { FadeInSection };
