import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { MdClose } from 'react-icons/md';

export interface BottomSheetProps {
  backdrop?: boolean;
  closeOnBackdropClicked?: boolean;
  open: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  title?: string | React.ReactNode;
  footer?: React.ReactNode;
  showX?: boolean;
  children?: React.ReactNode;
  onClose?: (result?: any) => void;
  className?: string;
  overChildrenComponent?: React.ReactNode;
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
  backdrop = true,
  open,
  setOpen,
  title,
  footer,
  showX = true,
  children,
  className,
  onClose,
}) => {
  const handleClose = () => {
    setOpen?.(false);
    onClose?.();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        className={'fixed left-0 bottom-0 w-full z-40'}
        onClose={handleClose}
      >
        {backdrop && (
          <Transition.Child
            enter="transition-opacity ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={classNames(
                'fixed inset-0',
                backdrop ? 'bg-black/70' : ''
              )}
              aria-hidden
            />
          </Transition.Child>
        )}

        <Dialog.Panel>
          <Transition.Child
            className="max-w-xl w-full mx-auto"
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div
              className={classNames(
                'relative mx-auto w-full max-w-xl max-h-[80vh] bg-white rounded-t-2xl flex flex-col box-border overflow-y-hidden'
              )}
            >
              {showX && (
                <button
                  onClick={handleClose}
                  className="absolute top-0 right-0 flex justify-center items-center p-4"
                >
                  <MdClose size={24} />
                </button>
              )}
              <Dialog.Title
                className={classNames('text-title-3', { 'mt-10': showX })}
              >
                {title}
              </Dialog.Title>
              <div className={classNames('overflow-y-auto', className)}>
                {children}
              </div>
            </div>
          </Transition.Child>
          {footer}
        </Dialog.Panel>
      </Dialog>
    </Transition.Root>
  );
};
