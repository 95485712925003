import React, { DetailedHTMLProps, forwardRef } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import classNames from 'classnames';

import { Helper } from '../../Helper';

export interface SelectProps
  extends DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  classes?: {
    optionsWrapper?: string;
  };
  showRequired?: boolean;
  required?: boolean;
  showOptional?: boolean;
  multiple?: boolean;
  name: string;
  label?: string;
  options: { value: string | number; label: string }[];
  error?: string;
  help?: string;
}

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      classes,
      showOptional,
      showRequired,
      required,
      label,
      name,
      multiple,
      options,
      error,
      help,
      ...rest
    },
    ref
  ) => {
    return (
      <div>
        {label && (
          <label
            htmlFor={name}
            className="block text-body-md font-bold text-gray-80 py-2"
          >
            {required && showRequired && (
              <span className="text-error font-normal">{'* '}</span>
            )}
            {label}
            {!required && showOptional && <span>{' (선택)'}</span>}
          </label>
        )}
        <div
          className={classNames(
            'grid grid-cols-2 gap-3',
            classes?.optionsWrapper
          )}
        >
          {options.map((option) => (
            <div
              key={`input-${name}-${option.label}-${option.value}`}
              className="bg-background-light rounded-2xl p-5 relative flex flex-row text-body-md text-gray-80 justify-between items-center focus-within:outline outline-primary active:opacity-70"
            >
              <input
                className="absolute top-0 left-0 w-full h-full opacity-0 peer cursor-pointer"
                type={multiple ? 'checkbox' : 'radio'}
                value={option.value}
                name={name}
                {...rest}
                ref={ref}
              />
              <span className="overflow-hidden whitespace-nowrap text-ellipsis">
                {option.label}
              </span>
              <MdCheckCircle
                size={28}
                className={'peer-checked:text-primary text-gray-60 shrink-0'}
              />
            </div>
          ))}
        </div>
        {error ? (
          <Helper variant={'error'}>{error}</Helper>
        ) : help ? (
          <Helper variant={'info'}>{help}</Helper>
        ) : null}
      </div>
    );
  }
);

Select.displayName = 'Select';
