import React, { useEffect, useState } from 'react'

import { useBexState } from '../core/useBexState'
import { fetchTreatment } from '../core/fetch'
import { UnitInfo } from './type'
import { removeCookieUnitId, setCookieUnitId } from '../core/util'

interface PropsBexFeature {
  featureKey: string
  children: React.ReactNode | ((unitInfo: UnitInfo) => React.ReactNode)
}

const process: string[] = []

const defaultUnitInfo: UnitInfo = { treatmentKey: 'A', isExposed: false }

const BexFeature = ({ featureKey, children }: PropsBexFeature) => {
  const { bexState } = useBexState()
  const [unitInfo, setUnitInfo] = useState<UnitInfo>(defaultUnitInfo)
  const [matchedChild, setMatchedChild] = useState<React.ReactNode>(null)
  const [match] = useState<'miss' | 'hit'>('miss')
  const [hasFetch, setHasFetch] = useState<boolean>(false)

  useEffect(() => {
    const { unitId, userId } = bexState
    if (!unitId) {
      return
    }

    const checkAndFetch = async () => {
      // process 배열에 experimentKey가 없을 경우
      if (!process?.find((k) => k === featureKey)) {
        process.push(featureKey)
        try {
          const res = await fetchTreatment(featureKey, unitId, userId)
          if (res.statusCode === 200) {
            const { data } = res
            const { status } = data
            const { treatment, isExposed, unitId: newUnitId } = data?.unit || {}
            setHasFetch(true)
            setUnitInfo({
              treatmentKey: treatment.key,
              isExposed: status === 'running' && isExposed,
            })
            if (newUnitId) setCookieUnitId(newUnitId)
          } else {
            if (res.statusCode === 400 && res.message === 'not found unit') {
              removeCookieUnitId()
            }
          }
        } catch (error) {
          console.error(`Error during fetch of feature ${featureKey}:`, error)
        } finally {
          process.splice(process.indexOf(featureKey), 1)
        }
      } else {
        setTimeout(checkAndFetch, 50)
      }
    }

    checkAndFetch()

    // 초기화시 받아오는 pick을 캐시로 적용하는 케이스, 일단 제외
    // if (bexState.pick[featureKey]) {
    //   setTreatment(bexState.pick[experimentKey])
    //   return
    // }
  }, [featureKey, bexState])

  useEffect(() => {
    // check children is function
    if (typeof children === 'function') {
      setMatchedChild(children(unitInfo))
      return
    }

    if (unitInfo.isExposed) setMatchedChild(children)
  }, [unitInfo, hasFetch])

  return (
    <div className={`bex ${featureKey}`} data-bex-matched={match}>
      {matchedChild}
    </div>
  )
}

export { BexFeature }
