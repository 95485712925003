import classNames from 'classnames';
import React from 'react';

export enum ListStyle {
  BULLET = 'bullet',
  NUMBER = 'number',
  NONE = 'none',
}

type ItemValueType = string | React.ReactNode;
type ItemType<T extends ItemValueType> = {
  key: string;
  value: T;
};
type ListType<T extends ItemValueType> = {
  classes?: {
    container?: string;
    listClass?: string;
    itemClass?: string;
  };
  title: string;
  items: ItemType<T>[];
  listStyle?: ListStyle;
};
type ItemProps = {
  key: number | string;
  index?: number;
  className?: string;
  children: React.ReactNode;
};
type ListProps = {
  className?: string;
  children: React.ReactNode;
};

const NumberList = ({ className, children }: ListProps) => {
  return <ol className={classNames(className)}>{children}</ol>;
};

const NormalList = ({ className, children }: ListProps) => {
  return <ul className={classNames(className)}>{children}</ul>;
};

const getListComponent = (listStyle: ListStyle) => {
  switch (listStyle) {
    case ListStyle.NUMBER:
      return NumberList;
    case ListStyle.BULLET:
    case ListStyle.NONE:
    default:
      return NormalList;
  }
};

const BulletItem = ({ key, className, children }: ItemProps) => {
  return (
    <li
      key={key}
      className={classNames("before:content-['•'] before:px-2 flex", className)}
    >
      {children}
    </li>
  );
};

const NumberItem = ({ key, index, className, children }: ItemProps) => {
  const listPrefix = index?.toString() !== '' ? `${Number(index) + 1}.` : '1.';
  const beforeClass = `before:content-['${listPrefix}']`;
  return (
    <li
      key={key}
      className={classNames(beforeClass, 'before:px-2 flex', className)}
    >
      {children}
    </li>
  );
};

const NormalItem = ({ key, className, children }: ItemProps) => {
  return (
    <li key={key} className={classNames(className)}>
      {children}
    </li>
  );
};

const getItemComponent = (listStyle: ListStyle) => {
  switch (listStyle) {
    case ListStyle.BULLET:
      return BulletItem;
    case ListStyle.NUMBER:
      return NumberItem;
    case ListStyle.NONE:
    default:
      return NormalItem;
  }
};

const List = <T extends ItemValueType>({
  classes,
  title,
  items,
  listStyle = ListStyle.NONE,
}: ListType<T>) => {
  const List = getListComponent(listStyle);
  const Item = getItemComponent(listStyle);
  return (
    <div
      className={classNames(
        'flex flex-col gap-3 px-6 py-8',
        classes?.container
      )}
    >
      <p className="text-gray-90 text-caption-b">{title}</p>
      <List className={classes?.listClass}>
        {items.map((item: ItemType<T>, index) => {
          return (
            <Item key={item.key} index={index} className={classes?.itemClass}>
              {item.value}
            </Item>
          );
        })}
      </List>
    </div>
  );
};

export default List;
