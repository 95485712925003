import React from 'react';
import classNames from 'classnames';

interface SkeletonProps {
  type?: string;
  className?: string;
}

export const Skeleton: React.FC<SkeletonProps> = ({
  type,
  className: _className,
}) => {
  const className = _className;
  // ?.split(' ')
  // .map((c) => '!' + c)
  // .join(' ');

  switch (type) {
    case 'text-head-3':
      return (
        <div className={classNames('flex items-center w-full', className)}>
          <div
            className={classNames(
              'w-full h-[2.125rem]',
              'animate-pulse bg-gray-300 rounded-full dark:bg-gray-300'
            )}
          ></div>
        </div>
      );
    case 'text-label-1-b':
      return (
        <div
          className={classNames(
            'flex items-center w-full h-[1.4625rem]',
            className
          )}
        >
          <div
            className={classNames(
              'w-full h-[1.125rem]',
              'animate-pulse bg-gray-300 rounded-full dark:bg-gray-300'
            )}
          ></div>
        </div>
      );
    case 'text-label-2':
      return (
        <div
          className={classNames(
            'flex items-center w-full h-[1.5rem]',
            className
          )}
        >
          <div
            className={classNames(
              'w-full h-[1rem]',
              'animate-pulse bg-gray-300 rounded-full dark:bg-gray-300'
            )}
          ></div>
        </div>
      );
    case 'text-label-3':
      return (
        <div
          className={classNames(
            'flex items-center w-full h-[1.1375rem]',
            className
          )}
        >
          <div
            className={classNames(
              'w-full h-[0.875rem]',
              'animate-pulse bg-gray-300 rounded-full dark:bg-gray-300'
            )}
          ></div>
        </div>
      );
    default:
      return (
        <div
          className={classNames(
            className,
            'w-full h-[1.5rem]',
            'animate-pulse bg-gray-300 rounded-full dark:bg-gray-300'
          )}
        ></div>
      );
  }
};
