import React from 'react';

export const EnvironmentBanner = ({ rotate }: { rotate?: boolean }) => {
  const env = process.env.VERCEL_ENV;

  if (!env || env === 'production') {
    return null;
  }

  if (rotate)
    return (
      <div className="fixed top-[82px] left-[-32px] w-[160px] py-[2px] bg-[#ff4136] text-center text-white text-[10px] rotate-[-45deg] origin-[0_0] z-50 tracking-widest">
        {env.toUpperCase()}
      </div>
    );

  return (
    <div className="fixed top-0 left-0 w-[100px] py-[2px] bg-[#ff4136] text-center text-white text-[10px] z-50 tracking-widest">
      {env.toUpperCase()}
    </div>
  );
};
