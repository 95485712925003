import classNames from 'classnames';
import React, { DetailedHTMLProps, forwardRef } from 'react';
import { IconType } from 'react-icons/lib';
import { MdCheckCircle } from 'react-icons/md';

import { Helper } from '../../Helper';

export interface CheckInputProps
  extends Omit<
    DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'type' | 'prefix'
  > {
  classes?: {
    input?: string;
  };
  showRequired?: boolean;
  showOptional?: boolean;
  variant?: 'text' | 'box';
  label?: string;
  prefix?: IconType | null;
  suffix?: IconType | null;
  error?: string;
  help?: string;
}

export const CheckInput = forwardRef<HTMLInputElement, CheckInputProps>(
  (
    {
      classes,
      showRequired,
      showOptional,
      variant = 'text',
      label,
      prefix: Prefix = MdCheckCircle,
      suffix: Suffix,
      placeholder,
      error,
      help,
      ...rest
    },

    ref
  ) => {
    const id = `check-input-${rest.name}`;
    return (
      <div className="flex flex-col text-body-md">
        {label && (
          <div className="text-label-1-b py-2">
            {showRequired && rest.required && (
              <span className="text-error font-normal">{'* '}</span>
            )}
            {label}
            {showOptional && !rest.required && <span>{' (선택)'}</span>}
          </div>
        )}
        {variant === 'text' ? (
          <label
            htmlFor={id}
            className={classNames(
              'relative rounded-lg focus-within:outline outline-primary cursor-pointer active:opacity-70 flex flex-row justify-between items-center p-2',
              classes?.input
            )}
          >
            <input
              id={id}
              ref={ref}
              {...rest}
              type={'checkbox'}
              className={classNames(
                'peer absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer',
                rest.className
              )}
            />
            {Prefix && (
              <Prefix
                size={24}
                className="mr-2 flex-shrink inline-block peer-checked:text-primary text-border-20"
              />
            )}
            <span className="flex-1 overflow-hidden whitespace-nowrap text-ellipsis">
              {placeholder}
            </span>
            {Suffix && (
              <Suffix
                size={24}
                className="ml-2 flex-shrink inline-block peer-checked:text-primary text-border-20"
              />
            )}
          </label>
        ) : (
          <label
            htmlFor={id}
            className={classNames(
              'relative overflow-hidden focus-within:outline outline-primary p-5 cursor-pointer rounded-2xl bg-background-light active:opacity-70 flex flex-row justify-between items-center',
              classes?.input
            )}
          >
            <input
              id={id}
              ref={ref}
              {...rest}
              type={'checkbox'}
              className={classNames(
                'peer absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer',
                rest.className
              )}
            />
            {Prefix && (
              <Prefix
                size={28}
                className="mr-3 flex-shrink inline-block peer-checked:!text-primary text-gray-60"
              />
            )}
            <span className="flex-1 overflow-hidden whitespace-nowrap text-ellipsis">
              {placeholder}
            </span>
            {Suffix && (
              <Suffix
                size={28}
                className="ml-3 flex-shrink inline-block peer-checked:text-primary text-gray-60"
              />
            )}
          </label>
        )}
        {error ? (
          <Helper variant={'error'}>{error}</Helper>
        ) : help ? (
          <Helper variant={'info'}>{help}</Helper>
        ) : null}
      </div>
    );
  }
);

CheckInput.displayName = 'CheckInput';
