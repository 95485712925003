import React from 'react';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox } from '../../Checkbox';
import { useSurvey } from '../../Survey/hooks/useSurvey';

export interface MultiSelectProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  cols?: number;
  name: string;
  label?: string;
  options?: { label: React.ReactNode; value: any }[];
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  cols = 1,
  name,
  label,
  options = [],
  ...rest
}) => {
  const { register, watch } = useFormContext();
  const { onItemSelect, stepProps, step } = useSurvey();

  return (
    <fieldset>
      <legend className="sr-only">{label}</legend>
      <div className={`grid grid-cols-${cols} gap-3`}>
        {options?.map(({ label, value }) => (
          <Checkbox
            onClick={() =>
              onItemSelect?.(name, value, watch(), {
                ...stepProps,
                idx: step - 1,
              })
            }
            key={`multi-select-${name}-${value}`}
            inputProps={{ type: 'checkbox', value, ...rest, ...register(name) }}
          >
            {label}
          </Checkbox>
        ))}
      </div>
    </fieldset>
  );
};
