import classNames from 'classnames';
import React from 'react';
import { IconType } from 'react-icons/lib';
import { MdHelp } from 'react-icons/md';

export interface HelperProps {
  icon?: IconType;
  className?: string;
  variant?: 'info' | 'warn' | 'error' | 'dark';
  children: React.ReactNode;
}

const HelperVariantMap = {
  info: 'text-info',
  warn: 'text-warn',
  error: 'text-error',
  dark: 'text-gray-90',
};

export const Helper: React.FC<HelperProps> = ({
  icon: Icon = MdHelp,
  className,
  variant = 'info',
  children,
}) => {
  return (
    <div
      className={classNames(
        'flex flex-row items-center',
        'py-1.5 text-caption',
        HelperVariantMap[variant],
        className
      )}
    >
      <Icon className="mr-1" size={20} />
      <p className="flex-1">{children}</p>
    </div>
  );
};
