import React, { DetailedHTMLProps, forwardRef } from 'react';
import classNames from 'classnames';

import { Helper } from '../../Helper';

export interface TextAreaProps
  extends Omit<
    DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    'prefix' | 'suffix' | 'children' | 'type'
  > {
  showRequired?: boolean;
  showOptional?: boolean;
  label?: React.ReactNode;
  error?: string;
  help?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      showRequired = false,
      showOptional = false,
      label,
      error,
      help,
      ...props
    },
    ref
  ) => {
    return (
      <div className="flex flex-col text-body-md">
        {label && (
          <div className="text-label-1-b py-2">
            {showRequired && props.required && (
              <span className="text-error font-normal">{'* '}</span>
            )}
            {label}
            {showOptional && !props.required && <span>{' (선택)'}</span>}
          </div>
        )}
        <div
          className={classNames(
            'flex flex-row p-4 rounded-2xl bg-background-light placeholder:text-gray-60 text-gray-90 items-center',
            'focus-within:outline outline-primary'
          )}
        >
          <textarea
            className="flex-1 bg-inherit outline-none overflow-hidden text-label-1"
            {...props}
            ref={ref}
          />
        </div>
        {error ? (
          <Helper variant={'error'}>{error}</Helper>
        ) : help ? (
          <Helper variant={'info'}>{help}</Helper>
        ) : null}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';
