import React, { MouseEventHandler } from 'react';
import { IoHeartOutline, IoHeartSharp } from 'react-icons/io5';
import classNames from 'classnames';

import { Button } from '../Button';

export type UpvoteProps = {
  currentUpvote: number;
  title?: string;
  isUpvoted: boolean;
  upvotingCallback: MouseEventHandler<HTMLButtonElement>;
  prefix?: React.FC;
};

export default function Upvote({
  currentUpvote,
  title,
  isUpvoted,
  upvotingCallback,
}: UpvoteProps) {
  return (
    <Button
      variant="outline"
      size="small"
      className={classNames(
        isUpvoted ? '!text-[#EC658C]' : '',
        '!bg-background-light hover:!bg-background-lighter'
      )}
      onClick={upvotingCallback}
      prefix={
        isUpvoted
          ? () => <IoHeartSharp style={{ color: '#EC658C', width: '18px' }} />
          : IoHeartOutline
      }
    >
      {title ?? '좋아요'}
      {currentUpvote > 0 && <span className="ml-1">{currentUpvote}개</span>}
    </Button>
  );
}
