import classNames from 'classnames';
import React, {
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

export default function SelectBox<
  T extends string | number | Record<string, any>
>({
  onSelect,
  selectedValue,
  options,
  displayLabel,
  placeholder = '선택해 주세요.',
  keyProp = 'id',
  valueProp = 'name',
  defaultValue,
  prefix,
  containerClassName,
  buttonClassName,
  ulClassName,
  liClassName,
  displaySelectedValue,
  autoChangeCurrentIndex,
}: {
  onSelect: (value: T) => void;
  selectedValue: T;
  options: T[];
  displayLabel: (value: T) => string;
  placeholder?: string;
  keyProp?: string;
  valueProp?: string;
  defaultValue?: T;
  prefix?: boolean;
  containerClassName?: string;
  buttonClassName?: string;
  ulClassName?: string;
  liClassName?: string;
  displaySelectedValue?: (value: T) => string;
  autoChangeCurrentIndex?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const selectWrapRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLButtonElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const [currentIndex, setCurrentIndex] = useState(
    selectedValue ? options.indexOf(selectedValue) : 0
  );
  const _displaySelectedValue = displaySelectedValue ?? displayLabel;

  useEffect(() => {
    if (autoChangeCurrentIndex) setCurrentIndex(options.indexOf(selectedValue));
  }, [selectedValue, autoChangeCurrentIndex]);

  useEffect(() => {
    if (isOpen) scrollIntoView(currentIndex);
  }, [isOpen]);

  useEffect(() => {
    scrollIntoView(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        selectWrapRef.current &&
        !selectWrapRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectWrapRef]);

  const selectCurrentOption = (idx: number) => {
    setCurrentIndex(idx);
    onSelect(options[idx]);
  };

  const moveFocusDown = () => {
    if (currentIndex < options.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const moveFocusUp = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    } else {
      setCurrentIndex(options.length - 1);
    }
  };

  const scrollIntoView = (idx: number) => {
    const listElement = listRef.current;
    const optionElement = listElement?.children[idx] as HTMLElement;

    if (listElement && optionElement) {
      // 옵션이 리스트의 맨 위로 가도록 스크롤 설정
      listElement.scrollTop = optionElement.offsetTop;
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (event) => {
    const { key } = event;
    if (key !== 'Tab') event.preventDefault();
    const openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' '];
    if (!isOpen && openKeys.includes(key)) {
      setCurrentIndex(selectedValue ? options.indexOf(selectedValue) : 0);
      setIsOpen(true);
      return;
    }

    switch (key) {
      case 'Escape':
        setIsOpen(false);
        break;
      case 'ArrowDown':
        moveFocusDown();
        break;
      case 'ArrowUp':
        moveFocusUp();
        break;
      case 'Enter':
      case ' ':
        if (isOpen) {
          selectCurrentOption(currentIndex);
          setIsOpen(false);
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div
        ref={selectWrapRef}
        className={classNames(
          'relative w-full rounded-[10px] border border-border-2',
          containerClassName
        )}
      >
        <button
          ref={selectRef}
          type="button"
          role="combobox"
          aria-controls="listbox"
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          className={classNames(
            'cursor-pointer p-4 text-label-1 bg-transparent w-full flex justify-between items-start text-left outline-none focus-visible:outline focus-visible:outline-primary focus-visible:outline-2 focus-visible:outline-offset-2 rounded-[10px]',
            buttonClassName
          )}
          onKeyDown={handleKeyDown}
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedValue !== undefined && selectedValue !== null
            ? _displaySelectedValue(selectedValue)
            : defaultValue
            ? typeof defaultValue === 'object'
              ? _displaySelectedValue(defaultValue[valueProp])
              : defaultValue
            : placeholder}
          {prefix &&
            (isOpen ? (
              <MdKeyboardArrowUp size={24} />
            ) : (
              <MdKeyboardArrowDown size={24} />
            ))}
        </button>
        {isOpen && (
          <ul
            ref={listRef}
            role="listbox"
            className={classNames(
              'absolute z-20 left-0 top-0 rounded-[10px] border border-border-2',
              'text-label-1 max-h-[400px] overflow-auto bg-white shadow-md',
              ulClassName
            )}
          >
            {options.map((option, idx) => (
              <li
                role="option"
                className={classNames(
                  'z-10 p-4 bg-white cursor-pointer hover:bg-f4Gray transition-colors text-center rounded-[10px]',
                  { '!bg-f4Gray font-bold': selectedValue === option },
                  {
                    'outline outline-primary outline-2 outline-offset-[-2px]':
                      currentIndex === idx,
                  },
                  liClassName
                )}
                key={
                  (typeof option === 'object' ? option[keyProp] : option) ?? idx
                }
                value={typeof option === 'object' ? option[valueProp] : option}
                onClick={() => {
                  selectCurrentOption(idx);
                  setIsOpen(false);
                }}
                aria-selected={selectedValue === option}
              >
                {displayLabel(option)}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}
