import React from 'react';

import { Button, ButtonProps } from '../Button';
import { useAddressSearch } from './hooks';

export interface AddressSearchSubmitProps
  extends Omit<ButtonProps, 'className'> {
  classes?: {
    container?: string;
    button?: string;
  };
}
export const AddressSearchSubmit: React.FC<AddressSearchSubmitProps> = (
  props
) => {
  const { selectedAddress, onSubmitted } = useAddressSearch();

  const { classes, ...rest } = props;

  return (
    <section className={classes?.container}>
      <Button
        {...rest}
        className={classes?.button}
        disabled={!selectedAddress}
        full
        onClick={(e) => {
          e.preventDefault();

          if (!selectedAddress) {
            return;
          }

          onSubmitted?.(selectedAddress);
          props.onClick?.(e);
        }}
      />
    </section>
  );
};
