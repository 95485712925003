import classNames from 'classnames';
import React from 'react';

import { NavigateBefore, NavigateNext } from '../svgs';

interface PagenationProps {
  currentPage: number;
  countPerPage: number;
  totalCount: number;
  onChange: (page: number) => void;
}

const Pagenation: React.FC<PagenationProps> = ({
  currentPage,
  countPerPage,
  totalCount,
  onChange,
}) => {
  const pagePerGroup = 5;
  const pageCount = Math.ceil(totalCount / countPerPage);
  const startPage =
    Math.floor((currentPage - 1) / pagePerGroup) * pagePerGroup + 1;
  const endPage = Math.min(startPage + pagePerGroup - 1, pageCount);
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  const isFirstGroup = startPage === 1;
  const isLastGroup = endPage === pageCount;

  const goToPreviousGroup = () => {
    const previousGroupStartPage = Math.max(startPage - pagePerGroup, 1);
    onChange(previousGroupStartPage);
  };

  const goToNextGroup = () => {
    const nextGroupStartPage = Math.min(startPage + pagePerGroup, pageCount);
    onChange(nextGroupStartPage);
  };

  return (
    <ul className="flex gap-1.5 justify-center">
      {!isFirstGroup && (
        <li>
          <button
            className={classNames(
              'w-10 h-10 p-2.5 rounded-lg flex-col justify-center items-center',
              'hover:bg-background-dark hover:border border-border-10'
            )}
            onClick={goToPreviousGroup}
          >
            <NavigateBefore />
          </button>
        </li>
      )}
      {pageNumbers.map((page, index) => {
        return (
          <li key={index}>
            <button
              className={classNames(
                'w-10 h-10 p-2.5 rounded-lg flex-col justify-center items-center font-bold',
                'hover:bg-background-dark',
                'hover:text-gray-90',
                currentPage === page ? 'bg-primary' : 'bg-white',
                { 'border border-border-10': currentPage !== page },
                { 'text-white': currentPage === page }
              )}
              onClick={() => onChange(page)}
            >
              <div>{page}</div>
            </button>
          </li>
        );
      })}
      {!isLastGroup && (
        <li>
          <button
            className={classNames(
              'w-10 h-10 p-2.5 rounded-lg flex-col justify-center items-center',
              'hover:bg-background-dark hover:border border-border-10'
            )}
            onClick={goToNextGroup}
          >
            <NavigateNext />
          </button>
        </li>
      )}
    </ul>
  );
};

export default Pagenation;
