import Link from 'next/link';
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  MouseEventHandler,
  forwardRef,
} from 'react';
import classnames from 'classnames';
import { IconBaseProps, IconType } from 'react-icons/lib';
export type ButtonVariant =
  | 'primary'
  | 'primary-low'
  | 'secondary'
  | 'outline'
  | 'dark'
  | 'target-notifications'
  | 'target-notifications-low'
  | 'custom'
  | 'pink'
  | 'ghost';
export type ButtonSize = 'large' | 'medium' | 'small';

export type ButtonAfixProps = IconBaseProps & { className?: string };

export interface ButtonProps {
  full?: boolean;
  htmlProps?: Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'children' | 'onClick' | 'type' | 'className'
  >;
  loading?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  children?: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  align?: 'right' | 'left' | 'center';
  className?: string;
  prefix?: IconType;
  prefixProps?: ButtonAfixProps;
  prefixComponent?: React.ReactNode;
  suffix?: IconType;
  suffixProps?: ButtonAfixProps;
  suffixComponent?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  vertical?: boolean;
  id?: string;
  testId?: string;
  link?: string;
}

const VariantStyles: Record<ButtonVariant, string> = {
  primary: 'border-primary text-white bg-primary',
  dark: 'text-white bg-gray-90',
  'primary-low': 'text-primary-text bg-primary-background',
  secondary: 'text-gray-90 bg-background-light',
  outline: 'border-[1px] border-border-2 bg-white text-gray-90',
  'target-notifications': 'text-white bg-[#5F2BFF]',
  'target-notifications-low': 'text-gray-90 bg-[#EFE9FF]',
  pink: 'text-white bg-[#FF638D]',
  custom: '',
  ghost: 'text-gray-90 bg-transparent',
};
//box-shadow: 0 0 0 2px gray inset;

const SizeStyles: Record<ButtonSize, string> = {
  large: 'px-4 py-3.5 text-label-1-b !border-lg',
  medium: 'p-3 text-label-2-b',
  small: 'p-2 text-label-2',
};

const IconSizes: Record<ButtonSize, number> = {
  large: 24,
  medium: 20,
  small: 18,
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      full,
      className,
      loading,
      htmlProps = {},
      type = 'button',
      variant = 'primary',
      size = 'medium',
      disabled = false,
      align = 'center',
      children,
      prefix,
      prefixProps,
      prefixComponent: PrefixComponent,
      suffix,
      suffixProps,
      suffixComponent: SuffixComponent,
      onClick,
      id,
      testId,
      link,
    },
    ref
  ) => {
    const { ...restHtmlProps } = htmlProps;

    if (link) {
      return (
        <Link href={link}>
          <button
            id={id}
            ref={ref}
            type={type}
            className={classnames(
              `rounded-lg box-border flex flex-row justify-${align} items-center`,
              ...(disabled
                ? ['bg-background-light text-gray-90 cursor-not-allowed']
                : [VariantStyles[variant], 'active:opacity-70']),
              SizeStyles[size],
              { 'w-full': full },
              className
            )}
            disabled={disabled || loading}
            onClick={onClick}
            data-testid={testId}
            {...restHtmlProps}
          >
            {prefix && (
              <div className={classnames('mr-1', prefixProps?.className)}>
                {React.createElement(prefix, {
                  size: IconSizes[size],
                  ...prefixProps,
                })}
              </div>
            )}
            {PrefixComponent && <div className="mr-1">{PrefixComponent}</div>}
            {children}
            {suffix && (
              <div className="ml-1">
                {React.createElement(suffix, {
                  size: IconSizes[size],
                  ...suffixProps,
                })}
              </div>
            )}
            {SuffixComponent && <div className="ml-1">{SuffixComponent}</div>}
          </button>
        </Link>
      );
    }

    return (
      <button
        id={id}
        ref={ref}
        type={type}
        className={classnames(
          `rounded-lg box-border flex flex-row justify-${align} items-center`,
          ...(disabled
            ? ['bg-background-light text-gray-90 cursor-not-allowed']
            : [VariantStyles[variant], 'active:opacity-70']),
          SizeStyles[size],
          { 'w-full': full },
          className
        )}
        disabled={disabled || loading}
        onClick={onClick}
        data-testid={testId}
        {...restHtmlProps}
      >
        {prefix && (
          <div className={classnames('mr-1', prefixProps?.className)}>
            {React.createElement(prefix, {
              size: IconSizes[size],
              ...prefixProps,
            })}
          </div>
        )}
        {PrefixComponent && <div className="mr-1">{PrefixComponent}</div>}
        {children}
        {suffix && (
          <div className="ml-1">
            {React.createElement(suffix, {
              size: IconSizes[size],
              ...suffixProps,
            })}
          </div>
        )}
        {SuffixComponent && <div className="ml-1">{SuffixComponent}</div>}
      </button>
    );
  }
);

Button.displayName = 'Button';
