import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { TextInput } from '../TextInput';
import { NumberPad } from './NumberPad';

interface NumberPadInputProps {
  className?: string;
  numberLabel?: string;
  placeholder?: string;
  maxLength?: number;
  isSecret?: boolean;
  setValue?: (value: string) => void;
  shuffle?: boolean;
  initOpen?: boolean;
  finishCallback?: () => void;
  openPad?: boolean;
  inputStyle?: React.CSSProperties;
}

export const NumberPadInput: React.FC<NumberPadInputProps> = ({
  className,
  numberLabel = '번호',
  placeholder,
  maxLength = 10,
  isSecret,
  setValue,
  shuffle,
  initOpen,
  finishCallback,
  openPad,
  inputStyle,
}) => {
  const [openNumberPad, setOpenNumberPad] = useState(initOpen || false);
  const [inputValue, setInputValue] = useState('');
  const scrollTargetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setValue) setValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (openPad) setOpenNumberPad(openPad);
  }, [openPad]);

  const handleFocus = () => {
    // Number pad 열기
    setOpenNumberPad(true);

    // 스크롤 로직
    const targetElement = scrollTargetRef.current;

    if (targetElement)
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div
      className={classNames(
        'px-4 flex flex-col',
        openNumberPad ? '' : 'justify-center',
        className
      )}
    >
      <div className={'mb-1'} ref={scrollTargetRef} />
      <TextInput
        type={isSecret ? 'password' : 'text'}
        onFocus={handleFocus}
        value={inputValue}
        maxLength={maxLength}
        placeholder={placeholder}
        autoComplete="off"
        style={inputStyle}
        readOnly={true}
        inputMode={'none'}
      />
      {openNumberPad && (
        <NumberPad
          numberLabel={numberLabel}
          setOpenNumberPad={setOpenNumberPad}
          maxLength={maxLength}
          setValue={setInputValue}
          defaultValue={inputValue}
          shuffle={shuffle}
          finishCallback={finishCallback}
          handleFocus={handleFocus}
        />
      )}
    </div>
  );
};

NumberPadInput.displayName = 'NumberPadInput';
