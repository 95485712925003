import React from 'react';
import classNames from 'classnames';

import { AddressSearchContext } from './context';
import { AddressItemData } from './types';
import { AddressSearchList } from './List';
import { AddressSearchItem } from './Item';
import { AddressSearchBar } from './SearchBar';
import { AddressSearchSubmit } from './Submit';

export interface AddressSearchComponentProps {
  classes?: {
    container?: string;
    form?: {
      container?: string;
      input?: string;
      button?: string;
    };
    list?: {
      container?: string;
      item?: string;
    };
  };
  addresses?: AddressItemData[];
  selectedAddress?: AddressItemData;
  onSelected?: (address: AddressItemData) => void;
  onSubmitted?: (address: AddressItemData) => void;
  isFetched?: boolean;
  isFetching?: boolean;
  children?: React.ReactNode;
}

export interface AddressSearchComponentType
  extends React.FC<AddressSearchComponentProps> {
  List: typeof AddressSearchList;
  Item: typeof AddressSearchItem;
  SearchBar: typeof AddressSearchBar;
  Submit: typeof AddressSearchSubmit;
}

export const AddressSearchComponent: AddressSearchComponentType = (props) => {
  const { classes, children } = props;

  return (
    <AddressSearchContext.Provider value={props}>
      <section className={classNames('flex flex-col', classes?.container)}>
        {children}
      </section>
    </AddressSearchContext.Provider>
  );
};

AddressSearchComponent.List = AddressSearchList;
AddressSearchComponent.Item = AddressSearchItem;
AddressSearchComponent.SearchBar = AddressSearchBar;
AddressSearchComponent.Submit = AddressSearchSubmit;
