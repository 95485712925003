import classNames from 'classnames';
import React from 'react';

export interface TipV2WrapperProps {
  className?: string;
  variant?: 'error' | 'info' | 'warn' | 'primary' | 'dark';
  title: React.ReactNode;
  description?: React.ReactNode;
  helperLabel?: string;
  offHelperLabel?: boolean;
  action?: React.ReactNode;
  classNameOption?: { title?: string; description?: string; helper?: string };
}

export const TipV2: React.FC<TipV2WrapperProps> = ({
  className,
  variant = 'info',
  title,
  description,
  helperLabel,
  offHelperLabel = false,
  action,
  classNameOption,
}) => {
  return (
    <div
      className={classNames(
        `p-4 text-caption rounded-xl flex flex-row gap-x-3`,
        {
          'bg-primary-background text-primary-text': variant === 'primary',
          'bg-info-background text-info': variant === 'info',
          'bg-warn-background text-warn': variant === 'warn',
          'bg-error-background text-error': variant === 'error',
          'bg-background-light text-gray-90': variant === 'dark',
        },
        className
      )}
    >
      <div className="flex flex-col w-full">
        <p
          className={classNames(
            `text-caption-b whitespace-pre-wrap`,
            classNameOption?.title || ''
          )}
        >
          {!offHelperLabel && (
            <span
              className={classNames(
                'mr-2 text-label-3-b py-0.5 px-1.5 text-white rounded-full',
                {
                  'bg-info': variant === 'info',
                  'bg-error': variant === 'error',
                  'bg-gray-90': variant === 'dark',
                  'bg-warn': variant === 'warn',
                  'bg-primary': variant === 'primary',
                },
                classNameOption?.helper || ''
              )}
            >
              {helperLabel || '도움말'}
            </span>
          )}
          {title}
        </p>
        {description && (
          <p
            className={classNames(
              'text-label-3 text-gray-70 mt-2 whitespace-pre-wrap',
              classNameOption?.description || ''
            )}
          >
            {description}
          </p>
        )}
        {action && <div className="mt-3">{action}</div>}
      </div>
    </div>
  );
};
