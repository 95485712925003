import { ReactNode, useMemo } from 'react';
import React from 'react';
import { MdChevronRight } from 'react-icons/md';

import { Button } from '../Button';
import { Modal } from '../Modal';

type NPSFlowProps = {
  score?: number;
  setScore?: (score: number) => void;
  reason: string;

  showModal?: boolean;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  isAnswered?: boolean;
  notFound?: boolean;
  icon: ReactNode;
  completedButtonText: string;

  requestNPS: ({ score, reason }: { score: number; reason: string }) => void;
  handleComplete: () => void;
  handleCompleted: () => void;
  handleReasonChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const NPSFlow: React.FC<NPSFlowProps> = ({
  score,
  setScore,
  reason,

  showModal,
  isAnswered,
  notFound,
  icon,
  completedButtonText,

  requestNPS,
  handleComplete,
  handleCompleted,
  handleReasonChange,
}) => {
  const title = useMemo(() => {
    return score
      ? `${score}점을 선택한 이유는 무엇인가요?`
      : '케어파트너를 친구/지인 등 주변에\n얼마나 추천하고 싶으신가요?';
  }, [score]);

  const description = useMemo(() => {
    return score
      ? '자유롭게 답변을 남겨주세요'
      : '추천하고 싶지 않다: 1점\n추천하고 싶다: 10점';
  }, [score]);

  if (isAnswered || notFound) {
    return (
      <div className="mx-auto max-w-lg pt-8 px-5 flex flex-col gap-6">
        <header className="flex flex-col self-stretch gap-6">
          {icon}
          <p className="text-sub-title-b text-bdsGray-90 whitespace-pre-wrap">
            {'이미 응답이 완료되었거나 응답 가능한 설문조사가 없습니다.'}
          </p>
          <section className="footer mx-auto bg-white p-4 fixed bottom-0 inset-x-0 w-full flex flex-col items-center justify-center">
            <Button size="large" className="w-full" onClick={handleCompleted}>
              {completedButtonText}
            </Button>
          </section>
        </header>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-lg pt-8 px-5 flex flex-col gap-6">
      <Modal.Portal>
        <Modal
          open={showModal ?? false}
          closeOnBackdropClicked={false}
          onClose={handleComplete}
        >
          <div className="flex flex-col p-4 pt-6 gap-2">
            <p className="text-title-3 text-bdsGray-80">답변완료</p>
            <p className="text-body-md text-bdsGray-70">
              소중한 의견 감사합니다.
              <br />
              전달주신 의견을 통해 더욱 발전하는
              <br />
              케어파트너가 되겠습니다.
            </p>
            <Button
              className="mt-2 w-full"
              size="large"
              onClick={handleComplete}
            >
              확인
            </Button>
          </div>
        </Modal>
      </Modal.Portal>
      <header className="flex flex-col self-stretch gap-6">
        {icon}
        <div className="flex flex-col justify-center gap-1 self-stretch">
          <p className="text-sub-title-b text-bdsGray-90 whitespace-pre-wrap">
            {title}
          </p>
          <p className="text-body-md text-info whitespace-pre-wrap">
            {description}
          </p>
        </div>
      </header>
      <section>
        {!score ? (
          <div className="grid grid-cols-5 grid-rows-2 gap-x-3 gap-y-4">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
              return (
                <div
                  key={'score_' + i}
                  onClick={() => setScore?.(i)}
                  className="text-center text-sub-title-b py-3 border-[1px] border-border-20 rounded-full"
                >
                  {i}
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <textarea
              onChange={handleReasonChange}
              className="flex w-full h-[280px] p-4 items-start border-2 border-border-20 rounded-[10px]"
            />
            <section className="footer mx-auto bg-white p-4 fixed bottom-0 inset-x-0 w-full flex flex-col items-center justify-center">
              <Button
                disabled={reason === ''}
                className="!w-full !max-w-[480px] mx-auto"
                suffix={MdChevronRight}
                size="large"
                onClick={() => {
                  requestNPS({ score, reason });
                }}
              >
                제출
              </Button>
            </section>
          </div>
        )}
      </section>
    </div>
  );
};

export default NPSFlow;
