import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';

import { SurveyStep } from './SurveyStep';
import { SurveyProps } from './types';
import { SurveyContext } from './contexts/SurveyContext';
import { Stepper } from '../Stepper';

export const Survey: React.FC<SurveyProps> = ({
  loading = false,
  name,
  steps = [],
  initialStep,
  onStepChange,
  onItemSelect,
  onNextStep,
  onPrevStep,
  onDone,
  onClose,
}) => {
  const defaultValues = useMemo(
    () =>
      steps
        .flatMap((step) => [...step.items])
        .flatMap((item) => ({
          name: item.name,
          defaultValue: item.defaultValue,
        }))
        .reduce((acc, cur) => {
          return { ...acc, [cur.name]: cur.defaultValue };
        }, {}),
    [steps]
  );

  const methods = useForm<Record<string, any>>({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const [step, setStep] = useState(
    initialStep && initialStep > 0
      ? Math.max(Math.min(steps.length, initialStep), 1)
      : 1
  );

  const idx = useMemo(() => step - 1, [step]);

  const next = () => setStep((prev) => Math.min(steps.length, prev + 1));
  const prev = () => setStep((prev) => Math.max(1, prev - 1));
  const go = (step: number) =>
    setStep(Math.max(Math.min(steps.length, step), 1));

  const current = useMemo(
    () =>
      steps.length > 0 ? (
        <SurveyStep key={`survey-${idx}-step`} {...steps[idx]} />
      ) : null,
    [idx, steps]
  );

  useEffect(() => {
    if (steps.length > 0) {
      onStepChange?.(steps[idx ?? 0]);
    }
  }, [idx]);

  return (
    <SurveyContext.Provider
      value={{
        loading,
        name,
        step,
        stepProps: steps[idx],
        steps,
        next,
        prev,
        go,
        onDone,
        onItemSelect,
        onNextStep,
        onPrevStep,
        onClose,
      }}
    >
      <FormProvider {...methods}>
        <header className="fixed left-0 top-0 bg-white w-full">
          <div
            className={
              'max-w-2xl mx-auto grid grid-cols-[56px_1fr_56px] items-center w-full h-16'
            }
          >
            <button
              className="h-full flex justify-center items-center"
              onClick={onClose}
            >
              <MdClose size={24} />
            </button>
            <div className="px-6">
              <Stepper
                variant="dark"
                className="max-w-md mx-auto"
                currentStep={step}
                steps={steps.length}
              />
            </div>
          </div>
        </header>
        <div className="pt-16 pb-24">{current}</div>
      </FormProvider>
    </SurveyContext.Provider>
  );
};
