import React, { useEffect, useState } from 'react';

interface CarouselProps {
  slides: React.ReactElement[];
  duration?: number;
}

export const Carousel: React.FC<CarouselProps> = ({
  slides,
  duration = 1500,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isTransitioning) {
        setCurrentSlide((prevSlide) =>
          prevSlide === slides.length - 1 ? 0 : prevSlide + 1
        );
        setIsTransitioning(true);
      }
    }, duration);

    return () => clearInterval(interval);
  }, [currentSlide, isTransitioning, slides.length]);

  const handleDotClick = (index: number) => {
    setIsTransitioning(false);
    setCurrentSlide(index);
  };

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  const getSlideTranslateStyle = (index: number) => {
    if (index === currentSlide) {
      return 'translate-x-0';
    } else if (index > currentSlide) {
      return 'translate-x-full';
    } else {
      return '-translate-x-full';
    }
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="relative w-full h-96 overflow-hidden">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute top-0 left-0 w-full h-full transition-transform duration-500 ${getSlideTranslateStyle(
              index
            )}`}
            onTransitionEnd={handleTransitionEnd}
          >
            {slide}
          </div>
        ))}
      </div>
      <div className="flex w-[180px] px-6 py-2 justify-between mt-4">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`min-w-[39px] h-1.5 rounded-full mx-1 cursor-pointer ${
              index === currentSlide ? 'bg-primary' : 'bg-background-dark'
            }`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};
