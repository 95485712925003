import { createContext } from 'react';

import { AddressSearchComponentProps } from '.';

export type AddressSearchContextValue = AddressSearchComponentProps;

export const AddressSearchContext = createContext<AddressSearchComponentProps>({
  addresses: [],
  isFetched: false,
});
