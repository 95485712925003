import classNames from 'classnames';
import React from 'react';

export interface StepperProps {
  variant?: 'primary' | 'error' | 'info' | 'warn' | 'dark';
  steps: number;
  currentStep: number;
  className?: string;
}

interface StepProps {
  key?: string;
  active?: boolean;
  bgClass?: string;
}

const Step: React.FC<StepProps> = ({ active, bgClass }) => {
  return (
    <div
      className={classNames(
        'transition-colors h-1.5 flex-1 bg-background-dark rounded-full'
      )}
    >
      <div
        className={classNames(
          'transition-all ease-out h-1.5 rounded-full',
          active ? 'w-full' : 'w-0',
          active ? bgClass : 'bg-background-dark'
        )}
      />
    </div>
  );
};

React.memo(Step, (prev, next) => {
  return prev.active !== next.active;
});

const VARIANT_CLASS_MAP: Record<
  NonNullable<StepperProps['variant']>,
  string
> = {
  dark: 'bg-gray-90',
  error: 'bg-error',
  warn: 'bg-warn',
  info: 'bg-info',
  primary: 'bg-primary',
};

export const Stepper: React.FC<StepperProps> = ({
  variant = 'primary',
  steps = 2,
  currentStep = 1,
  className,
}) => {
  const currentIdx = currentStep - 1;

  return (
    <ul
      role="progressbar"
      className={classNames('flex flex-row gap-x-0.5', className)}
    >
      {Array.from({ length: steps }, (_, i) => i).map((_, idx) => (
        <Step
          bgClass={VARIANT_CLASS_MAP[variant]}
          key={`stepper-${idx}`}
          active={currentIdx >= idx}
        />
      ))}
    </ul>
  );
};
