import React, { DetailedHTMLProps, forwardRef } from 'react';
import { IconType } from 'react-icons/lib';
import classNames from 'classnames';

import { Helper } from '../../Helper';

export interface TextInputProps
  extends Omit<
    DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'prefix' | 'suffix' | 'children'
  > {
  classes?: {
    wrapper?: string;
    innerWrapper?: string;
    inputWrapper?: string;
    input?: string;
  };
  showRequired?: boolean;
  showOptional?: boolean;
  label?: React.ReactNode;
  prefix?: IconType;
  suffix?: IconType;
  action?: React.ReactNode;
  error?: string;
  help?: string;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      classes,
      showRequired = false,
      showOptional = false,
      prefix,
      suffix,
      action,
      label,
      error,
      help,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={classNames('flex flex-col text-body-md', classes?.wrapper)}
      >
        {label && (
          <div className="text-label-1-b py-2">
            {showRequired && props.required && (
              <span className="text-error font-normal">{'* '}</span>
            )}
            {label}
            {showOptional && !props.required && <span>{' (선택)'}</span>}
          </div>
        )}
        <div
          className={classNames(
            'flex flex-row  rounded-2xl bg-background-light placeholder:text-gray-60 text-gray-90 items-center',
            'outline-0 focus-within:outline focus-within:outline-2 outline-primary',
            {
              '!bg-background-dark !cursor-not-allowed': props.disabled,
            },
            classes?.innerWrapper
          )}
        >
          <div
            className={classNames(
              'p-4 flex flex-row flex-1 overflow-hidden',
              classes?.inputWrapper || ''
            )}
          >
            {prefix && (
              <div className="flex flex-row items-center justify-center shrink mr-2 text-gray-60">
                {React.createElement(prefix, {
                  size: 24,
                })}
              </div>
            )}

            <input
              className={classNames(
                'flex-1 bg-inherit overflow-x-auto outline-none whitespace-nowrap text-ellipsis disabled:cursor-not-allowed',
                classes?.input || ''
              )}
              ref={ref}
              {...props}
            />
            {suffix && (
              <div className="flex flex-row items-center shrink ml-2 text-gray-60">
                {React.createElement(suffix, {
                  size: 24,
                })}
              </div>
            )}
          </div>
          {action && <div className="shrink p-2">{action}</div>}
        </div>
        {error ? (
          <Helper variant={'error'}>{error}</Helper>
        ) : help ? (
          <Helper variant={'info'}>{help}</Helper>
        ) : null}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';
