import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { Button } from '../Button';
import { Chip } from '../Chip';

type WithRedDotProps = {
  children: ReactNode;
  show?: boolean;
  dot?: ReactNode;
};
const WithRedDot: React.FC<WithRedDotProps> = ({ children, show, dot }) => {
  return (
    <div className="relative flex shrink-0">
      {show &&
        (dot ?? (
          <div className="absolute right-[calc(-15px)] sm:right-[calc(-18px)] top-[calc(-7px)]">
            <Chip
              size="small"
              className="!bg-red-500 !text-white !text-[9px] sm:!text-label-4 px-[4px] sm:!px-[5px]"
            >
              N
            </Chip>
          </div>
        ))}
      {children}
    </div>
  );
};

export const VerticalButton = ({
  containerClassName,
  title,
  icon,
  onClick,
  isNew,
  onlyDesktop = false,
  warnning = false,
}: {
  containerClassName?: string;
  title: {
    text: string | ReactNode;
    className: string;
    isNew?: boolean;
  };
  icon: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
  isNew?: boolean;
  onlyDesktop?: boolean;
  warnning?: boolean;
}) => {
  return (
    <div
      className={classNames(
        'cursor-pointer flex flex-1 flex-col items-center justify-center w-24 h-14 rounded-lg',
        `${onlyDesktop ? 'hidden sm:flex' : 'flex'}`,
        `hover:bg-background-dark`,
        containerClassName
      )}
      onClick={onClick}
    >
      {icon}
      <WithRedDot show={isNew ?? false}>
        <p
          className={classNames(title.className, {
            'text-warn': warnning,
          })}
        >
          {title.text}
        </p>
      </WithRedDot>
    </div>
  );
};

export const HorizontalButton = ({
  title,
  icon,
  onClick,
  onlyDesktop = false,
  className,
}: {
  title: string;
  icon: ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onlyDesktop?: boolean;
  warnning?: boolean;
  className?: string;
}) => {
  return (
    <Button
      className={classNames(
        className,
        'flex flex-1 items-center justify-center h-[52px] rounded-lg',
        `${onlyDesktop ? 'hidden sm:flex' : 'flex'}`
      )}
      prefixComponent={icon}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};
