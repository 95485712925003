import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { MdChevronRight } from 'react-icons/md';
import _ from 'lodash';

import { SurveyItem } from './SurveyItem';
import { SurveyItemProps, SurveyStepProps } from './types';
import { useSurvey } from './hooks/useSurvey';
import { Button } from '../Button';

export const SurveyStep: React.FC<SurveyStepProps> = ({
  name,
  className,
  items,
}) => {
  const {
    loading,
    step,
    steps,
    next,
    prev,
    onNextStep,
    onPrevStep,
    onDone,
    onClose,
  } = useSurvey();
  const { watch } = useFormContext();
  const formData = watch();

  const isFirst = step === 1;
  const isLast = steps.length > 1 ? steps.length === step : true;

  const isValidItem = useCallback(
    (item: SurveyItemProps) => {
      if (!item.required) {
        return true;
      }

      if (item.schema) {
        return item.schema?.isValidSync(formData[item.name]);
      }

      const data = formData[item.name];

      if (Array.isArray(data)) {
        return !!data.length;
      }

      if (typeof data === 'boolean') {
        return !_.isNil(data);
      }

      return !!data;
    },
    [formData]
  );

  const isValid = useCallback(
    () => items.every(isValidItem),
    [items, isValidItem]
  );

  const [valid, setValid] = useState(isValid());

  useEffect(() => {
    setValid(isValid());
  }, [formData, items, isValid]);
  const onlyOneItem = items?.length === 1;

  return (
    <>
      <div className={classNames('w-full px-5 max-w-2xl mx-auto', className)}>
        {items?.map((item) => (
          <SurveyItem key={`survey-step-${item.name}`} {...item} />
        ))}
      </div>
      <footer className="bg-white fixed bottom-0 w-full">
        <div className="border-t border-border-1 max-w-2xl mx-auto px-4 py-5 gap-x-3 flex flex-row">
          <Button
            disabled={loading}
            variant={'secondary'}
            className={classNames(onlyOneItem ? 'flex-1' : 'min-w-[72px]')}
            onClick={() => {
              if (isFirst) {
                return onClose?.(formData, {
                  name,
                  items,
                  idx: step,
                });
              }

              onPrevStep?.(formData, { name, items, idx: step });
              prev();
            }}
          >
            {steps.length === 1 ? '다음에 하기' : '이전'}
          </Button>
          <Button
            type={isLast ? 'submit' : 'button'}
            disabled={!valid || loading}
            className="flex-1"
            variant={isLast ? 'primary' : 'dark'}
            suffix={isLast ? undefined : MdChevronRight}
            onClick={
              isLast
                ? () => onDone?.(formData, { name, items, idx: step })
                : () => {
                    onNextStep?.(formData, { name, items, idx: step });
                    next();
                  }
            }
          >
            {isLast ? '입력 완료' : '다음'}
          </Button>
        </div>
      </footer>
    </>
  );
};
