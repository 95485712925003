import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

interface NumberPadProps {
  numberLabel?: string;
  maxLength?: number;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  setOpenNumberPad: React.Dispatch<React.SetStateAction<boolean>>;
  defaultValue?: string;
  shuffle?: boolean;
  finishCallback?: () => void;
  handleFocus?: () => void;
}

const generateRandomNumbers = () => {
  // 숫자 0부터 9까지를 랜덤하게 섞어서 배열 생성
  const shuffledNumbers = Array.from({ length: 10 }, (_, i) => i)
    .sort(() => Math.random() - 0.5)
    .toString()
    .split(',');
  shuffledNumbers[10] = shuffledNumbers[9];
  shuffledNumbers[9] = '삭제';
  shuffledNumbers[11] = '확인';
  return shuffledNumbers;
};

export const NumberPad: React.FC<NumberPadProps> = ({
  numberLabel = '번호',
  maxLength = 10,
  setValue,
  setOpenNumberPad,
  defaultValue,
  shuffle,
  finishCallback,
  handleFocus,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [afterFirstTouch, setAfterFirstTouch] = useState(false);

  const finishProcess = useCallback(() => {
    setOpenNumberPad(false);
    if (finishCallback) finishCallback();
  }, [finishCallback]);

  const onClickSetValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleFocus?.();
    if (!afterFirstTouch) setAfterFirstTouch(true);
    const newValue = e.currentTarget.value; // 클릭한 버튼의 value 가져오기
    if (newValue === '삭제') {
      setInputValue(inputValue.slice(0, -1));
    } else if (newValue === '확인') {
      finishProcess();
    } else if (maxLength && inputValue.length < maxLength) {
      setInputValue((prev) => prev + newValue);
    } else if (maxLength && inputValue.length === maxLength) {
      alert(`${numberLabel}은/는 ${maxLength}자를 초과 할 수 없습니다.`);
      finishProcess();
    }
  };

  useEffect(() => {
    if (setValue) setValue(inputValue);
    if (inputValue.length === maxLength && afterFirstTouch) finishProcess();
  }, [inputValue, maxLength]);

  const randomNumbers = useMemo(() => {
    if (shuffle) return generateRandomNumbers();
    return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '삭제', '0', '확인'];
  }, [shuffle]);

  return (
    <div
      className="fixed top-0 left-0 z-10 flex flex-col items-center justify-end w-full h-full"
      onClick={() => setOpenNumberPad(false)}
    >
      <p
        className={'text-title-2 text-center bg-white w-full py-2'}
      >{`${numberLabel} 입력해주세요`}</p>
      <div
        className="bg-white w-full max-w-4xl h-[350px] rounded bg-gray"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="grid grid-cols-3 grid-rows-4 gap-1 p-1 h-[350px] bg-gray-300 pb-16 pt-10 font-bold">
          {randomNumbers.map((value) => (
            <button
              key={value}
              className={classNames(
                'flex items-center justify-center text-3xl',
                {
                  'bg-white hover:bg-gray-200': !(
                    value === '삭제' || value === '확인'
                  ),
                  'bg-gray-300 hover:bg-gray-400': value === '삭제',
                  'bg-primary text-white': value === '확인',
                },
                'rounded'
              )}
              onClick={(e) => onClickSetValue(e)}
              value={value}
            >
              {value}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

NumberPad.displayName = 'NumberPad';
