import classNames from 'classnames';
import React, { useRef } from 'react';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

export const Toggle: React.FC<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = (props) => {
  const { checked } = props;

  const ref = useRef<HTMLInputElement>(null);
  return (
    <>
      <input ref={ref} {...props} hidden />
      <button
        onClick={() => ref.current?.click()}
        type="button"
        className={classNames(
          'rounded-full bg-border-10 p-1 w-12 transition-all',
          {
            '!bg-primary': checked,
          }
        )}
      >
        <div
          className={classNames(
            'transition-all relative rounded-full left-0 h-6 w-6 bg-white',
            {
              '!left-4': checked,
            }
          )}
        />
      </button>
    </>
  );
};
