import classNames from 'classnames';
import React from 'react';

export interface ScreenProps {
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  classes?: {
    container?: string;
    header?: string;
    footer?: string;
  };
  withAppBar?: boolean;
}

export const Screen: React.FC<ScreenProps> = ({
  header,
  children,
  footer,
  classes,
  withAppBar = false,
}) => {
  return (
    <main
      className={classNames(
        'flex flex-col text-gray-90 w-full max-w-2xl mx-auto',
        classNames(withAppBar ? 'min-h-[calc(100vh-4rem)]' : 'min-h-[100vh]')
      )}
    >
      {header && (
        <header
          className={classNames(
            'sticky top-0 bg-white flex felx-row items-center  w-full z-10',
            classes?.header
          )}
        >
          {header}
        </header>
      )}
      <section
        className={classNames(
          'flex flex-col flex-1 px-5 items-stretch',
          classes?.container
        )}
      >
        {children}
      </section>
      {footer && (
        <footer
          className={classNames(
            'sticky bottom-0 p-5 z-10 bg-white',
            classes?.footer
          )}
        >
          {footer}
        </footer>
      )}
    </main>
  );
};
