import React, { DetailedHTMLProps, forwardRef, useRef } from 'react';
import { IconType } from 'react-icons/lib';
import classNames from 'classnames';
import { MdAttachFile } from 'react-icons/md';

import { Helper } from '../../Helper';
import { Button } from '../../Button';

export interface FileInputProps
  extends Omit<
    DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'prefix' | 'suffix' | 'children' | 'ref' | 'placeholder'
  > {
  showRequired?: boolean;
  showOptional?: boolean;
  fileRegistered?: boolean;
  label?: React.ReactNode;
  placeholder?: React.ReactNode;
  prefix?: IconType;
  suffix?: IconType;
  error?: string;
  help?: string;
}

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      showRequired = false,
      showOptional = false,
      fileRegistered = false,
      prefix: Prefix = MdAttachFile,
      suffix: Suffix,
      label,
      error,
      help,
      placeholder,
      ...props
    },
    ref
  ) => {
    const labelRef = useRef<HTMLLabelElement | null>(null);

    return (
      <div className="flex flex-col text-body-md">
        {label && (
          <div className="text-label-1-b py-2">
            {showRequired && props.required && (
              <span className="text-error font-normal">{'* '}</span>
            )}
            {label}
            {showOptional && !props.required && <span>{' (선택)'}</span>}
          </div>
        )}
        <label
          htmlFor={`file-input-${props.name}`}
          className={classNames(
            'flex flex-row rounded-2xl bg-background-light text-gray-60 items-center relative',
            'focus-within:outline outline-primary cursor-pointer'
          )}
          ref={labelRef}
        >
          <div className="p-4 flex flex-row flex-1 overflow-hidden">
            {Prefix && (
              <div className="shrink mr-2 text-gray-60">
                <Prefix size={24} />
              </div>
            )}
            <input
              id={`file-input-${props.name}`}
              type={'file'}
              className="opacity-0 cursor-pointer absolute left-0 top-0 h-full w-full"
              {...props}
              onChange={(e) => {
                if (!e.target.files?.length && fileRegistered) {
                  e.preventDefault();
                  return;
                }

                props.onChange?.(e);
              }}
              ref={ref}
            />
            <span className="text-ellipsis overflow-hidden whitespace-nowrap">
              {placeholder}
            </span>
            {Suffix && (
              <div className="shrink ml-2 text-gray-60">
                <Suffix size={24} />
              </div>
            )}
          </div>
          <div className="shrink p-2">
            <Button
              variant={fileRegistered ? 'outline' : 'dark'}
              onClick={() => labelRef?.current?.click()}
            >
              {fileRegistered ? '다시 등록' : '파일 등록'}
            </Button>
          </div>
        </label>
        {error ? (
          <Helper variant={'error'}>{error}</Helper>
        ) : help ? (
          <Helper variant={'info'}>{help}</Helper>
        ) : null}
      </div>
    );
  }
);

FileInput.displayName = 'FileInput';
