import { useQuery } from '@tanstack/react-query'

const BASE_URL = process.env.NEXT_PUBLIC_BEX_API_URL as string
interface CacheItem {
  data: any
  timestamp: number
}
interface PendingRequest {
  promise: Promise<any>
  timestamp: number
}

const cache: Record<string, CacheItem> = {}
const pendingRequests: Record<string, PendingRequest> = {}
const CACHE_DURATION = 5 * 60 * 1000 // 5분

const handleError = (error: any) => {
  console.error(error)
  throw error
}

const fetchFromBexApi = async (endPoint: string) => {
  try {
    const response = await fetch(`${BASE_URL}${endPoint}`)
    return await response.json()
  } catch (error) {
    console.log('err:', error)
    handleError(error)
  }
}

const mutateFromBexApi = async (endPoint: string, method: string, body: any) => {
  try {
    const response = await fetch(`${BASE_URL}${endPoint}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    return await response.json()
  } catch (error) {
    handleError(error)
  }
}

const fetchInit = async ({
  unitId,
  userId,
  experimentFetchMode,
}: {
  unitId: string
  userId?: string
  experimentFetchMode?: string
}) => {
  const userIdQuery = userId ? `&userId=${userId}` : ''
  const modeQuery = experimentFetchMode ? `&experimentFetchMode=${experimentFetchMode}` : ''
  return fetchFromBexApi(`/experiment/init?unitId=${unitId}${modeQuery}${userIdQuery}`)
}

const fetchTreatment = async (
  experimentKey: string,
  unitId: string,
  userId?: string,
  noCache?: boolean
) => {
  const cacheKey = `${experimentKey}-${unitId}-${userId}`
  const now = Date.now()

  if (!noCache && cache[cacheKey] && now - cache[cacheKey].timestamp < CACHE_DURATION) {
    return cache[cacheKey].data
  }

  if (
    !noCache &&
    pendingRequests[cacheKey] &&
    now - pendingRequests[cacheKey].timestamp < CACHE_DURATION
  ) {
    return pendingRequests[cacheKey].promise
  }

  const userIdQuery = userId ? `?userId=${userId}` : ''
  const requestPromise = fetchFromBexApi(
    `/experiment/${experimentKey}/treatment/${unitId}${userIdQuery}`
  )
    .then((data) => {
      cache[cacheKey] = { data, timestamp: now }
      delete pendingRequests[cacheKey]
      return data
    })
    .catch((error) => {
      delete pendingRequests[cacheKey]
      throw error
    })

  pendingRequests[cacheKey] = { promise: requestPromise, timestamp: now }

  return requestPromise
}

const fetchTreatmentByUserId = async (experimentKey: string, userId: string) => {
  return fetchFromBexApi(`/experiment/${experimentKey}/treatment/user/${userId}`)
}

const useFetchTreatment = (experimentKey: string, unitId: string, userId?: string) => {
  return useQuery(
    ['treatment', experimentKey, unitId, userId],
    () => fetchTreatment(experimentKey, unitId, userId),
    {
      staleTime: 1000 * 60 * 10,
      cacheTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
    }
  )
}

const fetchAllExperimentsForUser = async (unitId: string) => {
  return fetchFromBexApi(`/experiment/unitId/${unitId}`)
}

const forcedAssignment = async ({
  type = 'unitId',
  experimentId,
  value,
  treatmentKey,
}: {
  type?: string
  experimentId: string
  value: string
  treatmentKey: string
}) => {
  const body: { type: string; value: string; treatmentKey: string } = {
    type,
    value,
    treatmentKey,
  }
  return mutateFromBexApi(`/experiment/${experimentId}/forcedAssignment`, 'POST', body)
}

const fetchSSR = async (context: any, experimentKey: string) => {
  const unitId = context.req.cookies.unit_id
  const resData = await fetchTreatment(experimentKey, unitId, undefined, true)

  if (!resData?.data?.unit) {
    return false
  }

  const { treatment, isExposed } = resData.data.unit

  return isExposed && treatment.key === 'B'
}

export {
  fetchInit,
  fetchTreatment,
  fetchTreatmentByUserId,
  useFetchTreatment,
  fetchAllExperimentsForUser,
  forcedAssignment,
  fetchSSR,
}
